<!--Skeleton à utiliser comme ci dessous-->
<!--<SkeletonTable :rows="6" :columns="2"/>-->

<template>
    <b-skeleton-table :rows="rows" :columns="columns" :table-props="{ bordered: false }"></b-skeleton-table>
</template>

<script>
export default {
    name: 'SkeletonTable',
    props: {
        rows: {
            type: Number,
            default: 5
        },
        columns: {
            type: Number,
            default: 4
        }
    }
}
</script>